<template>
    <div>
        <b-card>
            <template slot="header">
                <h4 class="mt-2 custom-card-header-text">Members</h4>
                <ul class="list-inline float-right">
                    <li v-if="communities.length>1" class="list-inline-item"><label for="communityFilter" class="custom-card-header-filter">Community:</label></li>
					<li v-if="communities.length>1" class="list-inline-item">
						<b-form-select v-model="selected" @change="filterByCommunity()" class="custom-card-header-filter" id="communityFilter">
							<option :value="null" selected disabled>Community</option>
							<option value="all">All</option>
							<option v-for="community in communities" :key="community._id" v-bind:value="community._id">{{ community.name}}</option>
						</b-form-select>
					</li>
					<li v-if="circles.length>=1" class="list-inline-item"><label for="circleFilter" class="custom-card-header-filter">Circle:</label></li>
					<li v-if="circles.length>=1" class="list-inline-item">
						<b-form-select v-model="selectedCircle" @change="filterByCircle()" class="custom-card-header-filter" id="circleFilter">
							<option :value="null" selected disabled>Circle</option>
							<option value="all">All</option>
							<option value="none">No Circle</option>
							<option v-for="(circle, idx) in circles" :key="`${idx}$-{circle.short}`" v-bind:value="circle.short" v-if="circle.name && circles.length>=1">{{ circle.name}}</option>
						</b-form-select>
					</li>
					<li class="list-inline-item">
						<!-- <button type="button" @click="exportMemberProfileChange()" class="btn custom-card-header-filter btn-dropbox exportMemberProfileChange" v-b-tooltip.hover.bottom title="Download members with changes only" ><i class="cil-highligt"></i></button> -->
						<button type="button" aria-label="Export" v-if="exportBtn && isSuperAdmin" aria-live="assertive" @click="memberDownloadRequest" v-b-tooltip.hover.bottom title="Download member data" class="btn custom-card-header-filter btn-dropbox tab-focus">
						<span v-if="isLoading"><div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></span>
						<span v-else>
							<i class="icon-cloud-download"></i>
						</span>
						</button>
						<!--<button type="button" v-if="processBtn" v-b-tooltip.hover.bottom title="Generating report" class="btn custom-card-header-filter btn-dropbox"><i class="fa fa-spinner fa-spin"></i></button>-->
						<!-- <button type="button" v-if="downloadBtn" @click="downloadCSV()" v-b-tooltip.hover.bottom title="Download all member data" class="btn custom-card-header-filter btn-dropbox"><i class="fa fa-download"></i></button> -->
					</li>
				</ul>
			</template>
			<b-card-body>
				<!-- v-on:filter="listenTo"  -->
				<v-server-table ref="membersTable" :url="serverURL" :columns="columns" :options="options" name="dataTable" id="dataTable" class="members-table">
					<span slot="community" slot-scope="props">
                        {{ props.row.community ? props.row.community : 'N/A'}}
                    </span>
					<span slot="name" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                        {{ props.row.firstname}} {{ props.row.lastname}}
                    </span>
					<span slot="name" slot-scope="props" v-else>
                        {{ props.row.firstname}} {{ props.row.lastname}}
                    </span>
					<span slot="email" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                        {{ props.row.email}}
                    </span>
					<span slot="email" slot-scope="props" v-else-if = "props.row.email">
                        {{ props.row.email}}
                    </span>
					<!-- <span slot="last_request" slot-scope="props" v-if="props.row.last_request_made">
                        {{ props.row.last_request_made | momentDate }}
                    </span>
                    <span slot="last_request" v-else>N/A</span> -->
					<span slot="action" slot-scope="props" class="res-req-action-width">
                        <ul class="list-inline mb-0 text-center">
                            <li class="list-inline-item">
                                <b-button class="tab-focus" v-b-tooltip.hover.bottom aria-label="View" aria-live="assertive" title="View the member" variant="dropbox" size="sm" @click="$router.push(`/engagement/members/${props.row.memberId}`)"><i class="icon-eye"></i></b-button>
                            </li>
                            <li class="list-inline-item" v-if="props.row.email" aria-hidden="true">
                                <b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Take action" variant="btn btn-dropbox btn-sm tab-focus" right>
                                    <template slot="button-content">
                                      <i class="icon-settings"></i>
                                    </template>
                                    <b-dropdown-item v-if="props.row.status == 'Active'" @click="unsubscribeMember(props.row.memberId, props.row.email, props.row.communityId)">Unsubscribe Member</b-dropdown-item>
									<b-dropdown-item v-if="props.row.status == 'Unsubscribed'" @click="subscribeMember(props.row.memberId, props.row.email, props.row.communityId)">Subscribe Member</b-dropdown-item>
                                    <b-dropdown-item @click="openEmailModal(props.row.email, props.row.memberId, props.row.communityId)" v-if="props.row.memberId && props.row.communityId && !props.row.email_address3">Edit Email</b-dropdown-item>
                                </b-dropdown>
                            </li>
                        </ul>
                    </span>
				</v-server-table>
			</b-card-body>
			<b-modal variant="tab-focus" id="email-modal" class="email-modal" size="md" v-model="emailModal" ok-title="Close" @ok="emailModal = false" title="Update Email">
				<b-card-body>
					<b-form v-on:submit.prevent="updateEmailSubmit">
						<b-form-group
													label="Email:"
													label-for="Email">
							<b-form-input
														type="text"
														name="email"
														v-model="updateEmail"
														v-validate="{required: true, max: 100, email: true}"
														:state="validateState('email')"
														placeholder="Enter email">
							</b-form-input>
							<b-form-invalid-feedback aria-live="polite" role="alert" v-if="errors.has('email')">
								{{ errors.first('email')}}
							</b-form-invalid-feedback>
						</b-form-group>
						<b-button type="submit" variant="primary tab-focus">Update</b-button>
					</b-form>
				</b-card-body>
				<template #modal-footer="{ cancel }">
                        <div>
                            <button class="btn custom-card-header-filter btn-primary tab-focus custom-close-button" @click="cancel()">Close</button>
                        </div>
                </template>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import {shuffleArray} from '@/shared/utils'
import axios from 'axios'
import {exportCSV} from '@/assets/js/common';
var moment = require('moment-timezone');
import '../../styles/Spinner.css';
import VueNotifications from 'vue-notifications'

export default {
	name: 'members',
	data() {
		return {
			isLoading: false,
			members: [],
			communities: [],
			circles: [],
			selected: 'all',
			isSuperAdmin: false,
			selectedCircle: 'all',
			perPage: 25,
			searchQuery: '',
			token: localStorage.getItem('token'),
			columns: ['community', 'circles', 'name', 'email', 'engagementPoints', 'capacity', 'propensity', 'account_owner', 'action'],
			options: {
				headings: {
					engagementPoints: 'Engagement Score',
					account_owner: 'Account Owner'
				},
				sortable: ['community', 'circles', 'name', 'email', 'engagementPoints', 'capacity', 'propensity', 'account_owner'],
				sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
				pagination: {
					chunk: 10,
					nav: 'fixed'
				},
				requestFunction: async function (data) {
					this.$parent.searchQuery = data.query;
					return await axios.get(this.url, {headers: {'x-access-token': localStorage.getItem('token')}, params: data}).catch(function (e) {
						if (e.response.status === 401) {
							localStorage.removeItem('authUser');
							this.$router.push('/login');
						}
					}.bind(this));
				},
				responseAdapter({data}) {
					this.$parent.membersCount = data.memberCount;
					return {
						data: data.membersData,
						count: data.memberCount
					}
				},
				filterable: true,
			},
			memberUri: `${process.env.VUE_APP_API_BASE_URL}/dashboard/members/count`,
			membersCount: 0,
			userData: {},
			serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/members`,
			exportBtn: true,
			processBtn: false,
			downloadBtn: false,
			emailModal: false,
			updateEmail: '',
			memberEmailOfId: '',
			communityId: ''
		}
	},
	notifications: {
		showWarnMsg: {
			type: VueNotifications.types.warn,
			message: 'No data found for export file'
		},
		showDownloadingMsg: {
			type: VueNotifications.types.success,
			timeout: 5000,
			message: "We have received your request to download your member data. Because of the sensitivity and size of this information, we will contact you to share the data privately and securely with you. Thank you."
		},
		showErrorMsg: {
			type: VueNotifications.types.error
		}
	},
	async created() {
		this.token = localStorage.getItem('token');
		await this.getCommunities();
		await this.loadData();
		const dict = {
			custom: {
				email: {
					required: "Please enter email.",
					max: "Email cannot be more than 100 characters.",
					email: 'Please enter valid email address.'
				}
			}
		};
		this.$validator.localize('en', dict);
	},
	methods: {
		getServerURL() {
			return `${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/members?circle=${this.selectedCircle}`;
		},
		loadData() {
			var user = localStorage.getItem('authUser');
			this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${JSON.parse(user)._id}/edit-profile`, {headers: {'x-access-token': this.token}}).then(response => {
				if (response.data.user) {
					this.userData = response.data.user;
					this.userData.role == "SuperAdmin" ? this.isSuperAdmin = true : this.isSuperAdmin = false;
					this.exportBtn = true;
					this.processBtn = false;
					this.downloadBtn = false;
					if (response.data.user.memberCSVFileName) {
						this.exportBtn = false;
						this.processBtn = false;
						this.downloadBtn = true;
					} else if (response.data.user.memberExportCSV) {
						this.exportBtn = false;
						this.processBtn = true;
						this.downloadBtn = false;
					}
				}
			}).catch(err => {
				if (err.response.status === 401) {
					localStorage.removeItem('authUser');
					this.$router.push('/login');
				}
			});

			var queryParams = {
				headers: {'x-access-token': this.token}
			}
			if (this.selected && this.selected != 'all') {
				queryParams.params = {community: this.selected};
			}
			this.$helpers.setTabindexOnTableCells();
		},
		checkData(value) {
			if (value) {return value;} else {return 'N/A';}
		},
		async getCommunities() {
			const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/sidebar-community`, {headers: {'x-access-token': this.token}})
				.catch(err => {
					if (err.response.status === 401) {
						localStorage.removeItem('authUser');
						this.$router.push('/login');
					}
				});
			this.communities = data;
			if (this.$route.params.community_id) {
				const selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
				if (selectedCommunity.length <= 1) {
					this.selected = selectedCommunity[0]._id;
				}
			} else {
				if (this.communities.length <= 1) {
					this.selected = this.communities[0]._id;
				}
			}
			this.filterByCommunity();
		},
		filterByCommunity: async function () {
			this.selectedCircle = "all";
			if (this.selected == "all") {
				this.circles = [];
			} else {
				this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
				this.circles.sort(function (a, b) {
					return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
				});
			}
			this.serverURL = this.getServerURL();
		},
		filterByCircle: async function () {
			this.serverURL = this.getServerURL();
		},
		// filterByCommunity: async function () {
		// 	this.selectedCircle = "all";
		// 	if (this.selected == "all") {
		// 		this.circles = [];
		// 	} else {
		// 		this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
		// 		this.circles.sort(function (a, b) {
		// 			return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
		// 		});
		// 	}
		// 	this.serverURL = this.getServerURL();
		// },
		// exportData: async function () {
		// 		this.showDownloadingMsg();
		// 		this.exportBtn = false;
		// 		this.processBtn = true;
		// 		this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.selected}/members?circle=${this.selectedCircle}&query=${this.searchQuery}`, {headers: {'x-access-token': this.token}}).then(async response => {
		// 			if (response.data.memberCount > 0) {
		// 				console.log("Got back member data, running Export to CSV file now", response.data.memberCount)
		// 				await exportCSV(response.data.membersData.slice(), 'members', ['requestId', 'memberId', 'communityId'], ['last_request_made', 'last_response_sent', 'last_request_received', 'last_response_received', 'terms_accepted']);
		// 				console.log("Done exporting to CSV")
		// 				this.exportBtn = true;
		// 				this.processBtn = false;
		// 			} else {
		// 				this.showWarnMsg();
		// 				this.exportBtn = true;
		// 				this.processBtn = false;
		// 			}
		// 		}).catch(err => {
		// 			this.showErrorMsg({message: 'There was an error while processing your request.'});
		// 			this.exportBtn = true;
		// 				this.processBtn = false;
		// 			if (err.response && err.response.status === 401) {
		// 				localStorage.removeItem('authUser');
		// 				this.$router.push('/login');
		// 			} else {
		// 				console.error('Error:', err);
		// 			}
		// 		});

		// },
		memberDownloadRequest: async function () {
			this.isLoading = true; 
			try {
				const response = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/member-download-response?community=${this.selected}`, {
					headers: { 'x-access-token': this.token }
				});
				this.showDownloadingMsg();
			} catch (err) {
				this.showErrorMsg({ message: 'There was an error while processing your request.' });

				if (err.response && err.response.status === 401) {
					localStorage.removeItem('authUser');
					this.$router.push('/login');
				}
			} finally {
				this.isLoading = false;
			}
		},
		redirectUrls: function (path) {
			window.open((`/engagement/members/${path}`), '_blank');
		},
		exportMemberProfileChange: function () {
			this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/members/memberProfileChanges/?community=${this.selected}&circle=${this.selectedCircle ? this.selectedCircle : ''}`, {headers: {'x-access-token': this.token}}).then(async response => {
				if (response.data.profileChanges.length) {
					var response = exportCSV(response.data.profileChanges.slice(), 'profileChanges', [], ['createdAt']);
				}
			}).catch(err => {
				if (err.response.status === 401) {
					localStorage.removeItem('authUser');
					this.$router.push('/login');
				}
			});
		},
		unsubscribeMember: async function (memberId, email, communityId) {
			if (communityId) {
				const unsubscribe = {
					unsubscribe:
					{
						email: email.toString(),
						reason: '',
						description: ''
					},
					community: communityId,
					memberId: memberId,
					isMailSent: false
				}
				await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/members/unsubscribe`, unsubscribe).then(async response => {
					this.$refs.membersTable.refresh();
				}).catch(err => {
					if (err.response.status === 401) {
						localStorage.removeItem('authUser');
						this.$router.push('/login');
					}
				});
			} else {
				this.showErrorMsg({message: 'Community not assigned'});
			}
		},
		subscribeMember: async function (memberId, email, communityId) {
			const data = {
				memberId: memberId,
				email: email,
				communityId: communityId
			}
			await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/members/subscribe`, data).then(async response => {this.$refs.membersTable.refresh();
			}).catch(err => {
				if (err.response.status === 401) {
					localStorage.removeItem('authUser');
					this.$router.push('/login');
				}
			});
		},
		openEmailModal: function (email, memberId, communityId) {
			this.emailModal = true;
			this.updateEmail = '';
			this.memberEmailOfId = memberId;
			this.communityId = communityId;
		},
		validateState(ref) {
			if (this.errors.has(ref)) {
				return !this.errors.has(ref);
			} else {
				return null;
			}
		},
		updateEmailSubmit() {
			this.$validator.validateAll().then(async result => {
				if (result) {
					let memberObj = {
						email: this.updateEmail,
						memberId: this.memberEmailOfId,
						communityId: this.communityId
					}
					await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/members/update-email`, memberObj, {headers: {'x-access-token': this.token}}).then(async response => {
						this.emailModal = false;
						this.$refs.membersTable.refresh();
					}).catch(err => {
						if (err.response.status === 401) {
							localStorage.removeItem('authUser');
							this.$router.push('/login');
						}
					});
				}
			})
		}
	}
}
</script>
